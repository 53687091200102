import {
  fetchSubIdPage,
  handleSubIdAdd,
  handleSubIdEdit,
  handleSubIdDel,
  handleSubIdStatus,
} from '@/api/offer/detail';
import Pagination from '@/components/pagination';

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      filter: {
        offerId: this.$route.query.offerId,
      },
      options: {
        country: [],
        status: ['active', 'close', 'hold'],
      },
      list: [],
      pages: {
        currentPage: 1,
        pageSize: 10,
      },
      pagesTotal: 0,
      // isShowPage: false,
      loading: {
        list: false,
      },
    };
  },
  mounted() {
    this.getList();
  },
  computed: {},
  methods: {
    formatValue(val) {
      if (val === 0) {
        return 'close';
      } else if (val === 1) {
        return 'active';
      } else if (val === 2) {
        return 'hold';
      } else {
        return val;
      }
    },
    getList() {
      this.loading.list = true;
      let query = Object.assign(this.filter, this.pages);
      fetchSubIdPage(query).then((res) => {
        let data = res.result;
        this.list = data.list;
        this.pagesTotal = data.total;
        this.loading.list = false;
      });
    },
    add(subId) {
      console.log('12546');
      this.$prompt('subId:填写为空则随机生成。填写特定的字符，则用填写的', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: subId,
        closeOnClickModal: false,
      })
        .then(({ value }) => {
          handleSubIdAdd({
            offerId: this.filter.offerId,
            subId: value,
          }).then((res) => {
            if (res.code === 200) {
              this.getList();
              this.$message({
                type: 'success',
                message: '添加成功!',
              });
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消添加',
          });
        });
    },
    edit(id, subId) {
      this.$prompt('subId', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        // inputPattern: /\S/,
        inputValidator: this.inputValidator,
        // inputErrorMessage: '不能为空！',
        inputValue: subId,
        closeOnClickModal: false,
      })
        .then(({ value }) => {
          handleSubIdEdit({
            id: id,
            offerId: this.filter.offerId,
            subId: value,
          }).then((res) => {
            if (res.code == 200) {
              this.getList();
              this.$message({
                type: 'success',
                message: '修改成功!',
              });
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '取消修改',
          });
        });
    },
    inputValidator(val) {
      if (val == '') return '不能为空！';
      var nokongge = /[^a-zA-Z0-9]/g;
      if (nokongge.test(val)) return '只能是数字和字母！';
    },
    del(id) {
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        customClass: 'maxWidthx100',
      })
        .then(() => {
          var query = `?offerId=${this.filter.offerId}&id=${id}`;
          handleSubIdDel(query).then((res) => {
            if (res.code == 200) {
              this.getList();
              this.$message({
                type: 'success',
                message: '删除成功!',
              });
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    updateStatus(id, status) {
      this.$confirm('确认修改?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        customClass: 'maxWidthx100',
      })
        .then(() => {
          handleSubIdStatus({
            id: id,
            offerId: this.filter.offerId,
            status: status,
          }).then((res) => {
            if (res.code == 200) {
              this.getList();
              this.$message({
                type: 'success',
                message: res.message,
              });
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          });
        });
    },
    // 分页监听
    handleCurrentChange(val) {
      this.pages.currentPage = val;
      this.getList();
    },
    handleSizeChange(size) {
      this.pages.pageSize = size;
      this.handleCurrentChange(1);
    },
  },
};
