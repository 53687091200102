<template>
  <div>
    <el-row class="row_line" :gutter="10">
      <el-form label-width="100px" size="mini">
        <el-col :lg="8" :md="20">
          <el-form-item label="Affiliate Id">
            <group-select
              v-model="affiliateIds"
              :data-source="affiliatesGroupList"
              multiple
              reserve-keyword
              :loading="affiliateLoading"
              clearable
              filterable
              class="w100"
              size="mini"
            />
          </el-form-item>
        </el-col>
        <el-col :lg="6" :md="20">
          <el-form-item label-width="0px">
            <el-button type="primary" @click="getAffOfferBlacklist(1)">Search</el-button>
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
    <el-form :model="affiliateBlack" label-width="100px" size="mini">
      <el-row :gutter="10">
        <el-col :lg="9" :md="20">
          <el-form-item label="AffiliateId">
            <group-select
              v-model="affiliateBlack.affiliateIds"
              :data-source="affiliatesGroupList"
              multiple
              reserve-keyword
              :loading="affiliateLoading"
              clearable
              filterable
              class="w100"
              size="mini"
              placeholder="请选择"
            />
          </el-form-item>
        </el-col>
        <el-col :lg="6" :md="20">
          <el-form-item label="Description">
            <el-input v-model="affiliateBlack.description"></el-input>
          </el-form-item>
        </el-col>
        <el-col :lg="8" :md="20">
          <el-form-item label-width="0px">
            <el-button type="primary" @click="addAffiliate">Add</el-button>
            <el-button type="primary" @click="delAffiliate">Delete</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-table
      :data="affiliateBlackList"
      highlight-current-row
      @selection-change="handleAffiliateBlackSelectionChange"
      border
      stripe
      class="w100"
      size="mini"
    >
      <el-table-column type="selection" width="55" align="center"></el-table-column>
      <el-table-column label="AffiliateId" prop="affiliateId" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span class="cor337ab7" @click="toAffiliateDetailClick(scope.row)">{{
            scope.row.affiliateId
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Affiliate Name" align="center">
        <template slot-scope="scope">
          <span class="cor337ab7" @click="toAffiliateDetailClick(scope.row)">{{
            scope.row.affiliateName
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Api Sync" prop="apiSync" align="center">
        <template slot-scope="scope">
          {{ scope.row.apiSync }}
        </template>
      </el-table-column>
      <el-table-column
        label="Description"
        prop="description"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column label="Operator" prop="operator" align="center"></el-table-column>
      <el-table-column label="Operate Time" prop="operateTime" align="center"></el-table-column>
    </el-table>
    <pagination
      class="block pagePanel"
      :pageTotal="offerAffBlacktotal"
      @handleSizeChange="handleofferAffBlackSizeChange"
      @handleCurrentChange="handleofferAffBlackCurrentChange"
      :pageSize="pageSize"
      :currentPage="currentPage"
    ></pagination>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import Pagination from '@/components/pagination';
  import GroupSelect from '@/components/GroupSelect';

  import { getAffBlacklist, addAffBlacklist, deleteAffBlacklist } from 'api/offer/detail';
  let clickTimer = null;
  export default {
    components: {
      Pagination,
      GroupSelect,
    },
    props: {
      offerId: {
        type: [String, Number],
        required: true,
      },
    },
    data() {
      return {
        affiliateIds: [],
        affiliateIdsArr: [],
        affiliateBlack: {},
        delArr: [],
        affiliateBlackList: [],
        offerAffBlacktotal: null,
        pageSize: 10,
        currentPage: 1,
      };
    },
    computed: {
      ...mapState('affiliate', {
        affiliatesGroupList: (state) => state.affiliatesGroupList,
        affiliateLoading: (state) => state.affiliateLoading,
      }),
    },
    mounted() {
      this.getAffiliateList();
      this.getAffOfferBlacklist();
    },
    methods: {
      ...mapActions('affiliate', ['getAffiliateList']),
      getAffOfferBlacklist(curPage) {
        if (curPage) {
          this.currentPage = curPage;
        }
        const param = {
          affiliateIds: this.affiliateIds.join(','),
          page: this.currentPage,
          pageSize: this.pageSize,
        };

        getAffBlacklist(this.offerId, param).then((response) => {
          if (response.code === 200) {
            this.affiliateBlackList = response.result;
            this.offerAffBlacktotal = response.total;
          }
        });
      },
      addAffiliate() {
        let param = {
          ...this.affiliateBlack,
        };

        this.addAffOfferBlacklist(param);
      },
      addAffOfferBlacklist(param) {
        addAffBlacklist(this.offerId, param).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Add Success',
              type: 'success',
            });
            this.getAffOfferBlacklist();
          } else {
            this.$message.error('Add Error:' + response.message);
          }
        });
      },
      deleteBlackAff(offerId) {
        const delArrStr = this.delArr.join(',');
        deleteAffBlacklist(offerId, delArrStr).then((response) => {
          if (response.code == 200) {
            this.$message({
              message: 'Delete Success',
              type: 'success',
            });
            this.getAffOfferBlacklist();
          } else {
            this.$message.error('Delete Error:' + response.message);
          }
        });
        this.delArr = [];
      },
      delAffiliate() {
        const length = this.affiliateBlackSelectionList.length;
        this.$confirm('Are you want to delete ?', 'Tip', {
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
        })
          .then(() => {
            for (let i = 0; i < length; i++) {
              this.delArr.push(this.affiliateBlackSelectionList[i].affiliateId);
            }
            this.deleteBlackAff(this.offerId);
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            });
          });
      },
      handleAffiliateBlackSelectionChange(val) {
        this.affiliateBlackSelectionList = val;
      },
      handleofferAffBlackSizeChange(val) {
        this.pageSize = val;
        this.handleofferAffBlackCurrentChange(1);
      },
      handleofferAffBlackCurrentChange(val) {
        this.currentPage = val;
        this.getAffOfferBlacklist();
      },
      toAffiliateDetailClick(row) {
        if (clickTimer) {
          window.clearTimeout(clickTimer);
          clickTimer = null;
        }
        var that = this;
        clickTimer = window.setTimeout(function() {
          that.$nextTick(() => {
            const { href } = that.$router.resolve({
              path: '/affiliate/detail',
              query: {
                affiliateId: row.affiliateId || row.affiliate_id,
              },
            });
            window.open(href, '_blank');
          });
        }, 300);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .row_line {
    border-bottom: 1px solid #ccc;
    margin-bottom: 16px;
  }
  .cor337ab7 {
    color: #337ab7;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
</style>
