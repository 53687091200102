/**
 * 验证数字和小数点
 * @params:rule
 */
var ValiNumberAndFloat = (rule, value, callback) => {
  let floatReg = /^[+]?(0|([1-9]\d*))(\.\d+)?$/g;
  let integerReg = /^[+]{0,1}(\d+)$/g;
  if (floatReg.test(value) || integerReg.test(value)) {
    return callback();
  }
  callback(new Error('请输入整数或者小数点'));
};

export { ValiNumberAndFloat };
