<template>
  <div>
    <el-form :model="permitConfig" label-width="100px" size="mini">
      <el-row :gutter="10">
        <el-col :lg="9" :md="20">
          <el-form-item label="AffiliateIds">
            <group-select
              v-model="affiliateIds"
              :data-source="affiliatesGroupList"
              multiple
              reserve-keyword
              :loading="affiliateLoading"
              clearable
              filterable
              class="w100"
            />
          </el-form-item>
        </el-col>
        <el-col :lg="8" :md="20">
          <el-form-item label-width="0px">
            <el-button type="primary" @click="getDataList(1)">Search</el-button>
            <el-button type="primary" @click="delPermit">Delete</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-form :model="permitConfig" label-width="100px" size="mini">
      <el-row :gutter="10">
        <el-col :lg="7" :md="20">
          <el-form-item label="AffiliateId">
            <group-select
              v-model="permitConfig.affiliateId"
              :data-source="affiliatesGroupList"
              reserve-keywords
              :loading="affiliateLoading"
              clearable
              filterable
              class="w100"
            />
          </el-form-item>
        </el-col>
        <!-- <el-col :lg="7" :md="20">
          <el-form-item label="Dailyc Cap">
            <el-input v-model="permitConfig.dailycCap" />
          </el-form-item>
        </el-col> -->

        <el-col :lg="8" :md="20">
          <el-form-item label-width="0px">
            <el-button type="primary" @click="addPermit">Add</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-table
      :data="permitList"
      highlight-current-row
      border
      stripe
      class="w100"
      size="mini"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column label="AffiliateId" prop="affiliateId">
        <template slot-scope="scope">
          <span class="cor337ab7" @click="toAffiliateDetailClick(scope.row)">{{
            scope.row.affiliateId
          }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="DailyCap" prop="dailyCap"></el-table-column> -->
      <el-table-column label="Creator" prop="createBy"></el-table-column>
      <el-table-column label="CreateTime" prop="createTime"></el-table-column>
    </el-table>
  </div>
</template>

<script>
  import GroupSelect from '@/components/GroupSelect';

  import { myMixin } from '@/mixins/mixins.js';
  import { getOfferPermitList, addOfferPermit, delOfferPermit } from '@/api/offer/detail';

  let clickTimer = null;
  export default {
    mixins: [myMixin],
    components: {
      GroupSelect,
    },
    props: {
      offerId: {
        type: [String, Number],
        required: true,
      },
      affiliateLoading: {
        type: Boolean,
        default: false,
      },
      affiliatesGroupList: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        affiliateIds: [],
        permitList: [],
        permitConfig: {},
        patchAffiliateIds: [],
      };
    },
    mounted() {
      this.getDataList();
    },
    methods: {
      getDataList(curPage) {
        if (curPage) {
          this.currentPage = curPage;
        }
        const param = {
          affiliateIds: this.affiliateIds,
          pageSize: this.pageSize,
          page: this.currentPage,
        };
        getOfferPermitList(this.offerId, param).then((response) => {
          if (response.code == 200) {
            this.permitList = response.result;
          }
        });
      },
      addPermit() {
        const param = {
          ...this.permitConfig,
        };
        addOfferPermit(this.offerId, param).then((response) => {
          if (response.code === 200) {
            this.$message.success('Add Success');
            this.getDataList();
          } else {
            this.$message.error(response.message);
          }
        });
      },
      delPermit() {
        const affiliateIds = this.patchAffiliateIds;
        if (Array.isArray(affiliateIds) && affiliateIds.length <= 0) {
          this.$message.warning('请先选择affiliate Id');
          return;
        }
        const param = affiliateIds.join();
        delOfferPermit(this.offerId, param).then((response) => {
          if (response.code === 200) {
            this.$message.success('Delete Success');
            this.getDataList();
          } else {
            this.$message.error('Delete Error:' + response.message);
          }
        });
      },
      handleSelectionChange(val) {
        this.patchAffiliateIds = val.map((item) => item.affiliateId);
      },
      toAffiliateDetailClick(row) {
        if (clickTimer) {
          window.clearTimeout(clickTimer);
          clickTimer = null;
        }
        var that = this;
        clickTimer = window.setTimeout(function() {
          that.$nextTick(() => {
            const { href } = that.$router.resolve({
              path: '/affiliate/detail',
              query: {
                affiliateId: row.affiliateId || row.affiliate_id,
              },
            });
            window.open(href, '_blank');
          });
        }, 300);
      },
    },
  };
</script>

<style></style>
