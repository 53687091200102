<template>
  <div class="offer_detail">
    <div class="main-Title bgff"><h2>Detail</h2></div>
    <div>
      <el-card>
        <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="Detail Info" name="first" lazy
            ><info :offerId="offerId" ref="info"
          /></el-tab-pane>
          <el-tab-pane label="Sub Id" name="subId" lazy><sub-id :offerId="offerId"/></el-tab-pane>
          <el-tab-pane label="Auto mix" name="autoMix" lazy>
            <auto-mix :offerId="offerId" :multipleSelection="[]" ref="autoMix" />
          </el-tab-pane>
          <el-tab-pane label="Pid Rule" name="pidRule" lazy>
            <pid-rule :offerId="offerId" />
          </el-tab-pane>
          <el-tab-pane label="Offer Status Log" name="statusLog" lazy
            ><status-log :offerId="offerId"
          /></el-tab-pane>
          <el-tab-pane label="Offer Affiliate Blacklist" name="fourth" lazy
            ><aff-blacklist :offerId="offerId"
          /></el-tab-pane>
          <el-tab-pane label="Offer Affiliate Sub Blacklist" name="fifth" lazy
            ><aff-sub-blacklist :offerId="offerId"
          /></el-tab-pane>
          <el-tab-pane label="Source Affiliate Blacklist" name="sixth" lazy
            ><source-aff-blacklist :offerId="offerId"
          /></el-tab-pane>
          <el-tab-pane label="Source Affiliate Sub Blacklist" name="serventh" lazy
            ><source-aff-sub-blacklist :offerId="offerId"
          /></el-tab-pane>
          <el-tab-pane label="Offer Affiliate Sub WhiteList" name="eighth" lazy
            ><offer-aff-sub-white-list :offerId="offerId"
          /></el-tab-pane>
          <el-tab-pane label="Offer Track Cap" name="eleventh" lazy
            ><offer-track-cap :offerId="offerId"
          /></el-tab-pane>
          <el-tab-pane label="Offer Affiliate Permit" name="fifteenth" lazy>
            <offer-aff-permit
              :offerId="offerId"
              :affiliateLoading="affiliateLoading"
              :affiliatesGroupList="affiliatesGroupList"
            />
          </el-tab-pane>
          <el-tab-pane label="Offer Affiliate Cap" name="twelfth" lazy
            ><offer-aff-cap :offerId="offerId"
          /></el-tab-pane>
        </el-tabs>
      </el-card>
    </div>
  </div>
</template>
<script>
  import Info from './components/Info';
  import AffBlacklist from './components/AffBlacklist';
  import AffSubBlacklist from './components/AffSubBlacklist';
  import SourceAffBlacklist from './components/SourceAffBlacklist';
  import SourceAffSubBlacklist from './components/SourceAffSubBlacklist';
  import OfferAffSubWhiteList from './components/OfferAffSubWhiteList';
  import OfferTrackCap from './components/OfferTrackCap';
  import OfferAffCap from './components/OfferAffCap.vue';
  import OfferAffPermit from './components/OfferAffPermit.vue';
  import StatusLog from './components/StatusLog.vue';
  import subId from './components/subId.vue';
  import autoMix from './components/autoMix.vue';
  import PidRule from './components/pidRule.vue';

  import { mapState, mapActions } from 'vuex';

  export default {
    components: {
      Info,
      AffBlacklist,
      AffSubBlacklist,
      SourceAffBlacklist,
      SourceAffSubBlacklist,
      OfferAffSubWhiteList,
      OfferTrackCap,
      OfferAffCap,
      OfferAffPermit,
      StatusLog,
      subId,
      autoMix,
      PidRule,
    },

    data() {
      return {
        offerId: this.$route.query.offerId + '',
        activeName: 'first',
      };
    },
    computed: {
      ...mapState('affiliate', {
        affiliatesGroupList: (state) => state.affiliatesGroupList,
        affiliateLoading: (state) => state.affiliateLoading,
      }),
    },
    mounted() {
      this.getAffiliateList();
    },
    methods: {
      ...mapActions('affiliate', ['getAffiliateList']),
      handleClick(tab) {
        if (tab && tab.name === 'first' && this.$refs.info && this.$refs.info.init) {
          this.$refs.info.init();
        } else if (tab && tab.name === 'autoMix' && this.$refs.autoMix && this.$refs.autoMix.init) {
          this.$refs.autoMix.init();
        }
      },
    },
  };
</script>
