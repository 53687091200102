import request from 'utils/request';
const api = {
  INSERT_OFFER: '/api/storage/offer',
  CREATIVES_ADDBATCH: '/api/creatives/addBatch',
  CREATIVES_ADDBATCH_LIST: '/api/creatives/list',
  CREATIVES_DELETE: '/api/creatives/delete',
};
export function insertOffer(data) {
  return request({
    url: api.INSERT_OFFER,
    method: 'post',
    data,
  });
}

export function handleCreativesAddBatch(data) {
  return request({
    url: api.CREATIVES_ADDBATCH,
    method: 'post',
    data,
  });
}

export function fetchCreativesList(query) {
  return request({
    url: api.CREATIVES_ADDBATCH_LIST,
    method: 'get',
    params: query,
  });
}

export function handleCreativesDelete(data) {
  return request({
    url: api.CREATIVES_DELETE,
    method: 'post',
    data,
  });
}
