<template>
  <div>
    <el-form size="mini">
      <el-row :gutter="10">
        <el-col :lg="7" :md="10">
          <el-form-item label="Date" label-width="50px">
            <el-date-picker
              v-model="startToEndDate"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              type="daterange"
              class="w100"
              align="right"
              unlink-panels
              range-separator="-"
              start-placeholder="Start Date"
              end-placeholder="End Date"
              :picker-options="pickerOptions"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :lg="8" :md="20">
          <el-form-item label-width="0px">
            <el-button type="primary" @click="getDataList(1)">Search</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-table
      :data="statusLogList"
      v-loading="tableLoading"
      highlight-current-row
      :header-cell-style="() => 'text-align:center'"
      :cell-style="() => 'text-align:center'"
      border
      stripe
      class="w100"
      size="mini"
    >
      <el-table-column label="Old Status" prop="oldStatus"> </el-table-column>
      <el-table-column label="New Status" prop="newStatus"> </el-table-column>
      <el-table-column label="Creator" prop="createBy"></el-table-column>
      <el-table-column label="CreateTime" prop="createTime"></el-table-column>
    </el-table>
    <pagination
      class="block pagePanel"
      :pageTotal="total"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :pageSize="pageSize"
      :currentPage="currentPage"
    ></pagination>
  </div>
</template>

<script>
  import Pagination from '@/components/pagination';
  import dateUtils from '@/utils/dateutils.js';
  import { myMixin } from '@/mixins/mixins.js';
  import { getStatusLog } from '@/api/offer/detail';
  export default {
    mixins: [myMixin],
    components: {
      Pagination,
    },
    props: {
      offerId: {
        type: [Number, String],
        required: true,
      },
    },
    data() {
      return {
        statusLogList: [],
        tableLoading: false,
        pageSize: 20,
        currentPage: 1,
        total: 0,
      };
    },
    mounted() {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() + 3600 * 1000 * 24);
      this.startToEndDate = [];
      this.startToEndDate[0] = dateUtils.date2StrYMD(end);
      this.startToEndDate[1] = dateUtils.date2StrYMD(start);
      this.getDataList();
    },
    methods: {
      getDataList(curPage) {
        if (curPage) {
          this.currentPage = curPage;
        }
        const param = {
          pageSize: this.pageSize,
          page: this.currentPage,
        };
        if (this.startToEndDate.length === 2) {
          param.fromDate = this.startToEndDate[0];
          param.toDate = this.startToEndDate[1];
        }
        this.tableLoading = true;
        getStatusLog(this.offerId, param)
          .then((response) => {
            if (response.code === 200) {
              this.statusLogList = response.result;
              this.total = response.total;
            } else {
              this.statusLogList = [];
              this.total = 0;
            }
          })
          .finally(() => {
            this.tableLoading = false;
          });
      },
      handleSizeChange(size) {
        this.pageParam.pageSize = size;
        this.handleCurrentChange(1);
      },
      handleCurrentChange(currentPage) {
        this.pageParam.page = currentPage;
        this.commonReportApi();
      },
    },
  };
</script>

<style></style>
