<template>
  <div class="ddj-country">
    <div class="mainBox">
      <el-form :model="postData" size="mini" ref="postData" :rules="rules" label-width="200px">
        <el-form-item label="status:" prop="status">
          <el-switch
            v-model="postData.status"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
        </el-form-item>
        <el-form-item label="控制方法:" prop="type">
          <el-radio-group v-model="postData.type">
            <el-radio :label="0">系统控制</el-radio>
            <el-radio :label="1">手动填写</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="postData.type == 0" label="更换频率(单位小时):" prop="frequency">
          <el-input-number
            v-model="postData.frequency"
            :min="1"
            :precision="0"
            label="请输入更换频率(单位小时)"
          ></el-input-number>
        </el-form-item>
        <el-form-item v-if="postData.type == 0" label="同时在跑pid个数:" prop="pidNum">
          <el-input-number
            v-model="postData.pidNum"
            :min="1"
            :precision="0"
            label="请输入同时在跑pid个数"
          ></el-input-number>
        </el-form-item>
        <el-form-item v-if="postData.type == 1" label="更换频率(单位小时):" prop="frequency1">
          <el-input-number
            v-model="postData.frequency"
            :min="1"
            :precision="0"
            label="请输入更换频率(单位小时)"
          ></el-input-number>
        </el-form-item>
        <el-form-item v-if="postData.type == 1" label="同时在跑pid个数:" prop="pidNum1">
          <el-input-number
            v-model="postData.pidNum"
            :min="1"
            :precision="0"
            label="请输入同时在跑pid个数"
          ></el-input-number>
        </el-form-item>
        <el-form-item v-if="postData.type == 1" label="pids:" prop="pids">
          <el-input
            type="textarea"
            maxlength="9000"
            :rows="8"
            v-model="postData.pids"
            placeholder="Please enter pids"
            resize="vertical"
            show-word-limit
          ></el-input>
          <el-button type="primary" size="small" @click="randomString" :loading="loading.randomBtn"
            >生成pid
          </el-button>
          <span> (点击一次按钮生成20个pid,逗号分隔开追加到框中)</span>
        </el-form-item>
        <div align="center" class="pt10">
          <el-button
            type="primary"
            size="small"
            @click="saveAll('postData')"
            :loading="loading.submitBtn"
            >Save
          </el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
  import { pidNumDetail, pidNumUpdate } from '@/api/offer/detail';

  export default {
    props: {
      offerId: {
        type: [String, Number],
        required: true,
      },
      type: {
        type: String,
        default: '',
      },
      openErrorDialog: {
        type: Function,
        default: null,
      },
      handleCloseDialog: {
        type: Function,
        default: null,
      },
    },
    data() {
      return {
        postData: {
          offerId: this.offerId,
          status: 0,
          type: 0,
          pids: '',
        },
        offer: {},
        loading: {
          submitBtn: false,
          randomBtn: false,
        },
        rules: {
          status: [{ required: true, message: 'Please select status', trigger: 'blur' }],
          type: [{ required: true, message: 'Please select type', trigger: 'blur' }],
          frequency: [
            { required: true, message: 'Please enter frequency', trigger: 'blur' },
            {
              type: 'number',
              min: 1,
              message: 'Please enter a number greater than 0',
              trigger: 'blur',
            },
          ],
          pidNum: [
            { required: true, message: 'Please select pidNum', trigger: 'blur' },
            {
              type: 'number',
              min: 1,
              message: 'Please enter a number greater than 0',
              trigger: 'blur',
            },
          ],
          pids: [{ required: true, message: 'Please enter pids', trigger: 'blur' }],
        },
      };
    },
    mounted() {
      this.getPidNumDetail();
    },
    methods: {
      getPidNumDetail() {
        pidNumDetail(this.offerId).then((res) => {
          if (res.result) {
            if (res.result.pids == null) {
              res.result.pids = '';
            }
            this.postData = res.result;
          } else {
            this.postData = {
              offerId: this.offerId,
              status: 0,
              type: 0,
              pids: '',
            };
          }
        });
      },
      randomString() {
        this.loading.randomBtn = true;
        if (this.postData.pids && this.postData.pids.length > 8640) {
          this.$message({
            type: 'error',
            message: '最多只能生成500个pid',
          });
          this.loading.randomBtn = false;
          return;
        }
        //随机13位字符串
        var result = '';
        for (var i = 0; i < 20; i++) {
          result += this.generateRandomString(13);
          if (i < 19) {
            result += ',';
          }
        }
        if (this.postData && this.postData.pids && this.postData.pids.length > 0) {
          this.postData.pids = this.postData.pids + ',' + result;
        } else {
          this.postData.pids = result;
        }
        this.loading.randomBtn = false;
      },
      generateRandomString(length) {
        var allChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var result = '';
        for (var i = 0; i < length; i++) {
          result += allChars.charAt(Math.floor(Math.random() * allChars.length));
        }
        return result + '_int';
      },
      saveAll(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.loading.submitBtn = true;
            pidNumUpdate(this.postData).then((res) => {
              if (res.code === 200) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                });
                this.loading.submitBtn = false;
                this.getPidNumDetail();
              } else {
                this.$message.error(res.message);
                this.loading.submitBtn = false;
              }
            });
          }
        });
      },
    },
  };
</script>

<style></style>
