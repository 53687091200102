<template>
  <div>
    <el-form size="mini">
      <el-row class="row_line" :gutter="10">
        <el-col :lg="8" :md="20">
          <el-form-item label="AffiliateId" label-width="100px">
            <group-select
              v-model="affiliateIds"
              :data-source="affiliatesGroupList"
              :loading="affiliateLoading"
              multiple
              clearable
              filterable
              class="w100"
              size="mini"
            />
          </el-form-item>
        </el-col>
        <el-col :lg="10" :md="20">
          <el-form-item>
            <el-button type="primary" @click="searchOfferTrackCapClick(1)">Search</el-button>
            <el-button type="primary" @click="delOfferTrackCapClick">Delete</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-form :model="offerTrackCap" label-width="100px" size="mini">
      <el-row :gutter="10">
        <el-col :lg="8" :md="20">
          <el-form-item label="AffiliateId">
            <group-select
              v-model="offerTrackCap.affiliateIds"
              :data-source="affiliatesGroupList"
              :loading="affiliateLoading"
              multiple
              clearable
              filterable
              class="w100"
              size="mini"
            />
          </el-form-item>
        </el-col>
        <el-col :lg="6" :md="20">
          <el-form-item label="Affiliate Sub">
            <el-input v-model="offerTrackCap.affSub"></el-input>
          </el-form-item>
        </el-col>
        <el-col :lg="7" :md="20">
          <el-form-item label="Daily Cap">
            <el-input v-model="offerTrackCap.dailyCap"></el-input>
          </el-form-item>
        </el-col>
        <el-col :lg="3" :md="20">
          <el-form-item label-width="0px">
            <el-button type="primary" @click="addOfferTrackCapClick">Add</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-table
      :data="offerTrackCapList"
      highlight-current-row
      @selection-change="handleofferTrackCapSelectionChange"
      border
      stripe
      class="w100"
      size="mini"
    >
      <el-table-column type="selection" width="55" align="center"></el-table-column>
      <el-table-column label="AffiliateId" align="center">
        <template slot-scope="scope">
          <span class="cor337ab7" @click="toAffiliateDetailClick(scope.row)">{{
            scope.row.affiliateId
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Affiliate Sub" prop="affSub" align="center"></el-table-column>
      <el-table-column label="Daily Cap" prop="dailyCap" align="center"></el-table-column>
      <el-table-column label="Operator" prop="operator" align="center"></el-table-column>
      <el-table-column
        label="Operate Time"
        prop="operateTime"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
    </el-table>
    <pagination
      class="block pagePanel"
      :pageTotal="offerTrackCapTotal"
      @handleSizeChange="handleofferTrackCapSizeChange"
      @handleCurrentChange="handleofferTrackCapCurrentChange"
      :pageSize="pageSize"
      :currentPage="currentPage"
    ></pagination>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import Pagination from '@/components/pagination';
  import GroupSelect from '@/components/GroupSelect';

  import { getAffClickCap, addAffClickCap, deleteAffClickCap } from 'api/offer/detail';
  export default {
    components: {
      Pagination,
      GroupSelect,
    },
    props: {
      offerId: {
        type: [String, Number],
        required: true,
      },
    },
    data() {
      return {
        affiliateIds: [],
        offerTrackCap: {},
        offerTrackCapList: [],
        offerTrackCapTotal: null,
        pageSize: 10,
        currentPage: 1,
      };
    },
    computed: {
      ...mapState('affiliate', {
        affiliatesGroupList: (state) => state.affiliatesGroupList,
        affiliateLoading: (state) => state.affiliateLoading,
      }),
    },
    mounted() {
      this.searchOfferTrackCapClick();
      this.getAffiliateList();
    },
    methods: {
      ...mapActions('affiliate', ['getAffiliateList']),
      searchOfferTrackCapClick(curPage) {
        if (curPage) {
          this.currentPage = curPage;
        }
        const param = {
          page: this.currentPage,
          pageSize: this.pageSize,
        };
        if (this.affiliateIds.length > 0) {
          param['affiliateIds'] = this.affiliateIds.join(',');
        }
        getAffClickCap(this.offerId, param).then((response) => {
          if (response.code === 200) {
            this.offerTrackCapList = response.result || [];
            this.offerTrackCapTotal = response.total;
          }
        });
      },
      delOfferTrackCapClick() {
        const length = this.offerTrackCapSelectionList.length;
        if (length <= 0) {
          this.$message.warning('你还没有选择要删除的数据哦~');
          return;
        }
        this.$confirm('Are you want to delete ?', 'Tip', {
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
        }).then(() => {
          for (let i = 0; i < length; i++) {
            this.delArr.push(this.offerTrackCapSelectionList[i].id);
          }
          const ids = this.delArr.join(',');
          deleteAffClickCap(this.offerId, ids).then((response) => {
            if (response.code == 0) {
              this.$message({
                message: 'Delete Success',
                type: 'success',
              });
              this.searchOfferTrackCapClick();
            } else {
              this.$message.error('Delete Error:' + response.message);
            }
          });
        });
        this.delArr = [];
      },
      addOfferTrackCapClick() {
        const param = {
          ...this.offerTrackCap,
        };
        addAffClickCap(this.offerId, param).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Add Success',
              type: 'success',
            });
            this.searchOfferTrackCapClick();
          } else {
            this.$message.error('Add Error:' + response.message);
          }
        });
      },
      handleofferTrackCapSizeChange(val) {
        this.pageSize = val;
        this.handleofferTrackCapCurrentChange(1);
      },
      handleofferTrackCapCurrentChange(val) {
        this.currentPage = val;
        this.searchOfferTrackCapClick();
      },
      handleofferTrackCapSelectionChange(val) {
        this.offerTrackCapSelectionList = val;
      },
    },
  };
</script>

<style></style>
