<template>
  <div class="ddj-country">
    <div class="mainBox">
      <el-form :model="filter" label-width="80px" size="small">
        <el-row :gutter="10">
          <el-col :span="2">
            <el-button type="primary" size="mini" class="mb10" @click="add()">Add</el-button>
          </el-col>
        </el-row>
      </el-form>
      <el-table
        :data="list"
        v-loading="loading.list"
        ref="listTableRef"
        style="width:100%;"
        height="70vmin"
        size="mini"
        border
        highlight-current-row
        class="mb10"
      >
        <el-table-column label="id" prop="id"></el-table-column>
        <el-table-column label="subId" prop="subId"></el-table-column>
        <el-table-column label="onTime" prop="onTime"></el-table-column>
        <el-table-column label="endTime" prop="endTime"></el-table-column>
        <el-table-column label="status" prop="status">
          <template slot-scope="scope">{{ formatValue(scope.row.status) }}</template>
        </el-table-column>
        <el-table-column label="Action" width="210" fixed="right">
          <template slot-scope="scope">
            <!--            <el-button-->
            <!--              type="primary"-->
            <!--              icon="el-icon-edit"-->
            <!--              circle-->
            <!--              size="mini"-->
            <!--              @click="edit(scope.row.id, scope.row.subId)"-->
            <!--            ></el-button>-->
            <!--            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              size="mini"
              @click="del(scope.row.id)"
            ></el-button>-->
            <el-dropdown size="mini" split-button v-if="scope.row.id" type="primary">
              Option>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <el-button
                    class="mb5"
                    style="width: 100%"
                    type="primary"
                    size="mini"
                    @click="edit(scope.row.id, scope.row.subId)"
                    >edit</el-button
                  >
                </el-dropdown-item>
                <el-dropdown-item
                  ><el-button
                    class="mb5"
                    style="width: 100%"
                    type="danger"
                    size="mini"
                    @click="del(scope.row.id)"
                    >delete</el-button
                  >
                </el-dropdown-item>
                <el-dropdown-item
                  ><el-button
                    class="mb5"
                    style="width: 100%"
                    type="warning"
                    size="mini"
                    @click="updateStatus(scope.row.id, 0)"
                    >closed</el-button
                  >
                </el-dropdown-item>
                <el-dropdown-item
                  ><el-button
                    class="mb5"
                    style="width: 100%"
                    type="warning"
                    size="mini"
                    @click="updateStatus(scope.row.id, 1)"
                    >active</el-button
                  >
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <div class="pagination-container pt-10" align="center">
        <!--        <el-pagination
          :page-size="pages.pageSize"
          :total="pagesTotal"
          background
          layout="total, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
        />-->
        <pagination
          :pageTotal="pagesTotal"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :pageSize="pages.pageSize"
          :currentPage="pages.currentPage"
        ></pagination>
      </div>
    </div>
  </div>
</template>

<script>
  import offerSubIdCon from '../../controllers/offer/subId';

  export default {
    name: 'offerSubId',
    ...offerSubIdCon,
  };
</script>

<style></style>
