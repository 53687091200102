<template>
  <div>
    <el-table
      :data="sourceSubBlackList"
      highlight-current-row
      border
      stripe
      class="w100"
      size="mini"
    >
      <el-table-column label="AffiliateId" align="center">
        <template slot-scope="scope">
          <span class="cor337ab7" @click="toAffiliateDetailClick(scope.row)">{{
            scope.row.affiliateId
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Affiliate Name" align="center">
        <template slot-scope="scope">
          <span class="cor337ab7" @click="toAffiliateDetailClick(scope.row)">{{
            scope.row.affiliateName
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="AffSub" prop="affSub" align="center"></el-table-column>
      <el-table-column label="SourceId" align="center">
        <template slot-scope="scope">
          <span class="cor337ab7" @click="sourceDetailClick(scope.row)">{{
            scope.row.sourceId
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="SourceName" align="center">
        <template slot-scope="scope">
          <span class="cor337ab7" @click="sourceDetailClick(scope.row)">{{
            scope.row.sourceName
          }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="Aff Sub" prop="affSub" align="center"></el-table-column> -->
      <el-table-column
        label="Description"
        prop="description"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column label="Operator" prop="operator" align="center"></el-table-column>
      <el-table-column
        label="Operate Time"
        prop="operateTime"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
    </el-table>
    <pagination
      class="block pagePanel"
      :pageTotal="sourceSubtotal"
      @handleSizeChange="handlesourceSubBlackSizeChange"
      @handleCurrentChange="handlesourceSubBlackCurrentChange"
      :pageSize="pageSize"
      :currentPage="currentPage"
    ></pagination>
  </div>
</template>

<script>
  import Pagination from '@/components/pagination';
  import { getSourceAffSubBlacklist } from 'api/offer/detail';
  let clickTimer = null;
  export default {
    components: {
      Pagination,
    },
    props: {
      offerId: {
        type: [String, Number],
        required: true,
      },
    },
    data() {
      return {
        sourceSubBlackList: [],
        sourceSubtotal: null,
        pageSize: 10,
        currentPage: 1,
      };
    },
    mounted() {
      this.getAffOfferSubSource();
    },
    methods: {
      getAffOfferSubSource() {
        let param = {
          page: this.currentPage,
          pageSize: this.pageSize,
        };
        getSourceAffSubBlacklist(this.offerId, param).then((response) => {
          if (response.code === 200) {
            this.sourceSubBlackList = response.result;
            this.sourceSubtotal = response.total;
          }
        });
      },
      handlesourceSubBlackSizeChange(val) {
        this.pageSize = val;
        this.handlesourceSubBlackCurrentChange(1);
      },
      handlesourceSubBlackCurrentChange(val) {
        this.currentPage = val;
        this.getAffOfferSubSource();
      },
      toAffiliateDetailClick(row) {
        if (clickTimer) {
          window.clearTimeout(clickTimer);
          clickTimer = null;
        }
        clickTimer = window.setTimeout(function() {
          this.$nextTick(() => {
            const { href } = this.$router.resolve({
              path: '/affiliate/detail',
              query: {
                affiliateId: row.affiliateId || row.affiliate_id,
              },
            });
            window.open(href, '_blank');
          });
        }, 300);
      },
    },
  };
</script>

<style></style>
